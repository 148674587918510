import type { Config } from "tailwindcss";

const config: Config = {
  content: [
    "./src/pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/components/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/app/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  theme: {
    colors: {
      red: "#C21121",
      "red-hover": "#67040d",
      transparent: "transparent",
      yellow: "#FFBE00",
      "yellow-hover": "rgba(248,195,0,0.76)",
      black: "#000000",
      white: "#FFFFFF",
    },
  },
  plugins: [],
  env: {
    NEXT_PUBLIC_BACK: process.env.NEXT_PUBLIC_BACK,
    REACT_APP_STEAMURL: 'https://steamcommunity.com/inventory/76561199633006965/440/2?count=260',
    REACT_APP_STEAMCOUNTURL: 'https://http-cors-proxy.p.rapidapi.com/',
    REACT_APP_HOST: 'http-cors-proxy.p.rapidapi.com',
    REACT_APP_RAPIDAPI_KEY: 'bc6cc9320bmsh29d648145187a9bp1cbe64jsn5840483ab567'
  },
};

export default config;
