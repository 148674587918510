import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
// @ts-ignore
import logo from './logo.png';
import dotenv from 'dotenv';
import config from "./tailwind.config";

function fetchJSONP(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const callbackName = `jsonp_callback_${Math.round(100000 * Math.random())}`;
        // @ts-ignore
        window[callbackName] = (data: any) => {
            // @ts-ignore
            delete window[callbackName];
            document.body.removeChild(script);
            resolve(data);
        };

        const script = document.createElement('script');
        script.src = `${url}${url.indexOf('?') >= 0 ? '&' : '?'}callback=${callbackName}`;
        script.onerror = (error: any) => {
            // @ts-ignore
            delete window[callbackName];
            document.body.removeChild(script);
            reject(error);
        };

        document.body.appendChild(script);
    });
}

function App() {
    const [totalCount, setTotalCount] = useState<number | null>(null);

    useEffect(() => {
        const options = {
            method: 'POST',
            url: config.env.REACT_APP_STEAMCOUNTURL,
            headers: {
                'x-rapidapi-key': config.env.REACT_APP_RAPIDAPI_KEY,
                'x-rapidapi-host': config.env.REACT_APP_HOST,
                'Content-Type': 'application/json',
                'X-Requested-With': 'www.example.com'
            },
            data: {
                url: config.env.REACT_APP_STEAMURL
            }
        };

        const fetchData = async () => {
            try {
                const response = await axios.request(options);

                const inventory = response.data;
                if (inventory && inventory.total_inventory_count !== undefined) {
                    setTotalCount(inventory.total_inventory_count);
                } else {
                    console.error('Error: Total inventory count not found');
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();

    }, []);

    return (
    <div className="App">
        <header>
            <nav>
                <ul>
                    <li>
                        <a href=""><img src={logo} alt="Logo" className="logo"/></a>
                        <div className="navbar-container">
                            <a href="#buy" className="navbar">Купить</a>
                            <a href="#sell" className="navbar">Продать</a>
                            <a href="#about" className="navbar">О нас</a>
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
       <main>
           <div>

           </div>
           <h1 className={"h1tittle"}>
               DEFL1K.RU - Самый выгодный магазин ключей Mann Co
           </h1>
           {/*<h2>*/}
           {/*    Мы недавно открылись*/}
           {/*</h2>*/}
           <div className="container">
               <div className="item">
                   <div className="buy">
                       <img id={"buy"} className="img"
                            src="https://optim.tildacdn.com/tild3031-3564-4563-b235-333765643264/-/resize/300x/-/format/webp/__-removebg-preview.png"/>
                       <div className="t-card__btn-wrapper">
                           <a href="https://t.me/liquid_trader" target={"_blank"} className="t-card__link" role="button" aria-haspopup="dialog">
                               <div className="t-card__btn t-btn t-btn_sm" id="cardbtn1_571903463"
                                    data-field="li_buttontitle__1495010928665" data-buttonfieldset="li_button"
                                    data-lid="1495010928665">Купить за 135 руб.
                               </div>
                           </a>
                           <span>
                               {totalCount !== null ? (
                                   <p className={"OnInv"}>В наличии: {totalCount-2}</p>
                               ) : (
                                   <p className={"OnInv"}>Loading...</p>
                               )}
                           </span>
                       </div>
                   </div>
               </div>
               <div className="item">
                   <div className="sell">
                       <img id="sell" className="img"
                            src="https://optim.tildacdn.com/tild3138-3763-4562-b136-623662613861/-/resize/300x/-/format/webp/__-removebg-preview.png"/>
                       <div className="t-card__btn-wrapper">
                           <a href="https://t.me/liquid_trader"target={"_blank"}  className="t-card__link" role="button" aria-haspopup="dialog">
                               <div className="t-card__btn t-btn t-btn_sm" id="cardbtn1_571903463"
                                    data-field="li_buttontitle__1495010928665" data-buttonfieldset="li_button"
                                    data-lid="1495010928665">Продать за 128 руб.
                               </div>
                           </a>
                           <span>
                               {totalCount !== null ? (
                                   totalCount > 252 ? (
                                       <p className={"OnInv"}>Можем купить: 0</p>
                                   ) : (
                                       <p className={"OnInv"}>Можем купить: {252 - totalCount}</p>
                                   )
                               ) : (
                                   <p className={"OnInv"}>Loading...</p>
                               )}
                           </span>
                       </div>
                   </div>
               </div>
           </div>
        <div id="about">
            <h1 className={"h1about"}>
                О сервисе
            </h1>
            <h2 className={"h2about"}>
                "DEFL1K.RU" - Сервис где вы можете купить или продать ключи Mann Co по лучшим ценам. Ключи - являются лучшим предметом для обмена, покупок или любых других целей.
                Их принимают на большинстве трейд-площадок, ведь их главное преимущество, что на них не распространяется 7-дневный бан трейда.
            </h2>
        </div>
       </main>

        <footer>
            <a>© DEFL1K.RU 2024. Все права защищены.</a>
        </footer>

    </div>
  );
}

document.title = "defl1k.ru - Сайт с самыми лучшими ценами ключей на рынке.";

export default App;
